import { Button, Input, Tooltip } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  inNumericKey,
  isAllowedOnNumberTypingKey,
  isNavigationKey,
  isNumpadNumericKey,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";
import {
  DismissSquareFilled,
  DismissSquareRegular,
  FlashAutoFilled,
  FlashAutoRegular,
} from "@fluentui/react-icons";
import { parseFloatBasedOnLocale } from "utils/utils";
// NOTE: all modules imported below may be imported from '@silevis/reactgrid'

export interface ClearedAmountCell extends Cell {
  type: "clearedamount";
  value: number;
  totalToBeCleared?: number;
  getClearanceDataTotal?: () => number;
  premiumDebt?: number;
  format?: Intl.NumberFormat;
  validator?: (value: number) => boolean;
  nanToZero?: boolean;
  hideZero?: boolean;
  t: any;
  selected?: boolean;
  highlighted?: boolean;
  errorMessage?: string;
  multiSelected?: boolean;
  setMultiselected?: (value: boolean) => void;
  setMultiselectedRange?: () => void;
  areThereMultiselectedRows?: () => boolean;
  deselectMultiselectedRows?: () => void;
  clearMultiselectedRows?: () => void;
  multiCleared?: boolean;
  setMultiCleared?: (value: boolean) => void;
  setMultiClearedRange?: () => void;
  areThereMultiClearedRows?: () => boolean;
  deselectMultiClearedRows?: () => void;
  clearMultiClearedRows?: () => void;
}

export class ClearedAmountCellTemplate
  implements CellTemplate<ClearedAmountCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ClearedAmountCell>
  ): Compatible<ClearedAmountCell> {
    let value: number;
    let getClearanceDataTotal: () => number;
    let setMultiselected: (value: boolean) => void;
    let setMultiselectedRange: () => void;
    let areThereMultiselectedRows: () => false;
    let deselectMultiselectedRows: () => void;
    let clearMultiselectedRows: () => void;
    let setMultiCleared: (value: boolean) => void;
    let setMultiClearedRange: () => void;
    let areThereMultiClearedRows: () => false;
    let deselectMultiClearedRows: () => void;
    let clearMultiClearedRows: () => void;

    let totalToBeCleared: number;
    let premiumDebt: number;
    let t: any;

    t = getCellProperty(uncertainCell, "t", "function");
    try {
      value = getCellProperty(uncertainCell, "value", "number");
    } catch (error) {
      value = NaN;
    }
    try {
      totalToBeCleared = getCellProperty(
        uncertainCell,
        "totalToBeCleared",
        "number"
      );
    } catch (error) {
      totalToBeCleared = NaN;
    }
    try {
      getClearanceDataTotal = getCellProperty(
        uncertainCell,
        "getClearanceDataTotal",
        "function"
      );
    } catch (error) {
      getClearanceDataTotal = undefined;
    }
    try {
      setMultiselected = getCellProperty(
        uncertainCell,
        "setMultiselected",
        "function"
      );
    } catch (error) {
      setMultiselected = undefined;
    }
    try {
      setMultiselectedRange = getCellProperty(
        uncertainCell,
        "setMultiselectedRange",
        "function"
      );
    } catch (error) {
      setMultiselectedRange = undefined;
    }
    try {
      areThereMultiselectedRows = getCellProperty(
        uncertainCell,
        "areThereMultiselectedRows",
        "function"
      );
    } catch (error) {
      areThereMultiselectedRows = undefined;
    }
    try {
      deselectMultiselectedRows = getCellProperty(
        uncertainCell,
        "deselectMultiselectedRows",
        "function"
      );
    } catch (error) {
      deselectMultiselectedRows = undefined;
    }
    try {
      clearMultiselectedRows = getCellProperty(
        uncertainCell,
        "clearMultiselectedRows",
        "function"
      );
    } catch (error) {
      clearMultiselectedRows = undefined;
    }

    try {
      setMultiCleared = getCellProperty(
        uncertainCell,
        "setMultiCleared",
        "function"
      );
    } catch (error) {
      setMultiCleared = undefined;
    }
    try {
      setMultiClearedRange = getCellProperty(
        uncertainCell,
        "setMultiClearedRange",
        "function"
      );
    } catch (error) {
      setMultiClearedRange = undefined;
    }
    try {
      areThereMultiClearedRows = getCellProperty(
        uncertainCell,
        "areThereMultiClearedRows",
        "function"
      );
    } catch (error) {
      areThereMultiClearedRows = undefined;
    }
    try {
      deselectMultiClearedRows = getCellProperty(
        uncertainCell,
        "deselectMultiClearedRows",
        "function"
      );
    } catch (error) {
      deselectMultiClearedRows = undefined;
    }
    try {
      clearMultiClearedRows = getCellProperty(
        uncertainCell,
        "clearMultiClearedRows",
        "function"
      );
    } catch (error) {
      clearMultiClearedRows = undefined;
    }

    try {
      premiumDebt = getCellProperty(uncertainCell, "premiumDebt", "number");
    } catch (error) {
      premiumDebt = NaN;
    }
    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }

    let multiSelected = false;
    try {
      multiSelected = getCellProperty(
        uncertainCell,
        "multiSelected",
        "boolean"
      );
    } catch {
      multiSelected = false;
    }

    let multiCleared = false;
    try {
      multiCleared = getCellProperty(uncertainCell, "multiCleared", "boolean");
    } catch {
      multiCleared = false;
    }

    const numberFormat =
      uncertainCell.format || new Intl.NumberFormat(window.navigator.language);
    const displayValue =
      uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;
    const text =
      Number.isNaN(displayValue) ||
      (uncertainCell.hideZero && displayValue === 0)
        ? ""
        : numberFormat.format(displayValue);
    return {
      ...uncertainCell,
      value: displayValue,
      text,
      t,
      getClearanceDataTotal,
      totalToBeCleared,
      premiumDebt,
      selected,
      highlighted,
      multiSelected,
      setMultiselected,
      setMultiselectedRange,
      areThereMultiselectedRows,
      deselectMultiselectedRows,
      clearMultiselectedRows,

      multiCleared,
      setMultiCleared,
      setMultiClearedRange,
      areThereMultiClearedRows,
      deselectMultiClearedRows,
      clearMultiClearedRows,
    };
  }

  handleKeyDown(
    cell: Compatible<ClearedAmountCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ClearedAmountCell>; enableEditMode: boolean } {
    if (isNumpadNumericKey(keyCode)) keyCode -= 48;
    const char = String.fromCharCode(keyCode);
    if (
      !ctrl &&
      !alt &&
      !shift &&
      (inNumericKey(keyCode) || isAllowedOnNumberTypingKey(keyCode))
    ) {
      const value = Number(char);
      if (Number.isNaN(value) && isAllowedOnNumberTypingKey(keyCode))
        return {
          cell: { ...this.getCompatibleCell({ ...cell, value }), text: char },
          enableEditMode: true,
        };
      return {
        cell: this.getCompatibleCell({ ...cell, value }),
        enableEditMode: true,
      };
    }

    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(
    cell: Compatible<ClearedAmountCell>,
    cellToMerge: UncertainCompatible<ClearedAmountCell>
  ): Compatible<ClearedAmountCell> {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
  }

  private getTextFromCharCode = (cellText: string): string => {
    switch (cellText.charCodeAt(0)) {
      case keyCodes.DASH:
      case keyCodes.FIREFOX_DASH:
      case keyCodes.SUBTRACT:
        return "-";
      case keyCodes.COMMA:
        return ",";
      case keyCodes.PERIOD:
      case keyCodes.DECIMAL:
        return ".";
      default:
        return cellText;
    }
  };

  getClassName(
    cell: Compatible<ClearedAmountCell>,
    isInEditMode: boolean
  ): string {
    const isValid = cell.validator?.(cell.value) ?? true;
    const className =
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "");
    return `${!isValid ? "rg-invalid" : ""} ${className}`;
  }

  render(
    cell: Compatible<ClearedAmountCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ClearedAmountCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    const locale = cell.format
      ? cell.format.resolvedOptions().locale
      : window.navigator.languages[0];
    const format = new Intl.NumberFormat(locale, {
      useGrouping: false,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    const groupingFormat = new Intl.NumberFormat(locale, {
      useGrouping: true,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    if (!isInEditMode) {
      const isValid = cell.validator?.(cell.value) ?? true;
      const textToDisplay =
        Number.isNaN(cell.text) || cell.text === ""
          ? groupingFormat.format(parseFloatBasedOnLocale("0"))
          : !isValid && cell.errorMessage
          ? cell.errorMessage
          : groupingFormat.format(parseFloatBasedOnLocale(cell.text));
      return (
        <div style={{ display: "flex", width: "100%" }}>
          {/* <Tooltip content={cell.t("pct.select.label")} relationship="label"> */}
          <Button
            appearance="subtle"
            size="small"
            icon={
              cell.multiSelected ? <FlashAutoFilled /> : <FlashAutoRegular />
            }
            onClick={(e) => {
              if (e.ctrlKey) {
                cell.setMultiselected(cell.multiSelected ? false : true);
                onCellChanged(
                  this.getCompatibleCell({
                    ...cell,
                  }),
                  true
                );
              } else if (e.shiftKey) {
                cell.setMultiselectedRange();
                onCellChanged(
                  this.getCompatibleCell({
                    ...cell,
                  }),
                  true
                );
              } else {
                if (cell.areThereMultiselectedRows()) {
                  if (cell.multiSelected) {
                    cell.clearMultiselectedRows();
                  } else {
                    cell.deselectMultiselectedRows();
                  }
                  onCellChanged(
                    this.getCompatibleCell({
                      ...cell,
                    }),
                    true
                  );
                } else {
                  if (cell.totalToBeCleared < 0) return;
                  let value =
                    cell.totalToBeCleared -
                    cell.getClearanceDataTotal() +
                    cell.value;
                  if (value < 0) value = -value;
                  else if (value > cell.premiumDebt) value = cell.premiumDebt;

                  onCellChanged(
                    this.getCompatibleCell({
                      ...cell,
                      value,
                    }),
                    true
                  );
                }
              }
            }}
          ></Button>
          {/* </Tooltip> */}
          {/* <Tooltip content={cell.t("pct.clear.label")} relationship="label"> */}
          <Button
            appearance="subtle"
            size="small"
            icon={
              cell.multiCleared ? (
                <DismissSquareFilled />
              ) : (
                <DismissSquareRegular />
              )
            }
            onClick={(e) => {
              if (e.ctrlKey) {
                cell.setMultiCleared(cell.multiCleared ? false : true);
                onCellChanged(
                  this.getCompatibleCell({
                    ...cell,
                  }),
                  true
                );
              } else if (e.shiftKey) {
                cell.setMultiClearedRange();
                onCellChanged(
                  this.getCompatibleCell({
                    ...cell,
                  }),
                  true
                );
              } else {
                if (cell.areThereMultiClearedRows()) {
                  if (cell.multiCleared) {
                    cell.clearMultiClearedRows();
                  } else {
                    cell.deselectMultiClearedRows();
                  }
                  onCellChanged(
                    this.getCompatibleCell({
                      ...cell,
                    }),
                    true
                  );
                } else {
                  onCellChanged(
                    this.getCompatibleCell({
                      ...cell,
                      value: 0,
                    }),
                    true
                  );
                }
              }
            }}
          ></Button>
          {/* </Tooltip> */}
          <div style={{ flexGrow: 1, textAlign: "right" }}>
            <Tooltip content={textToDisplay} relationship="label">
              <span>{textToDisplay}</span>
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <Input
        style={{ width: "100%", fontSize: "1em" }}
        size="small"
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
            input.classList.add("cell-input");
          }
        }}
        defaultValue={
          Number.isNaN(cell.value)
            ? this.getTextFromCharCode(cell.text)
            : cell.value + ""
        }
        onChange={(e) => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: parseFloat(
                e.currentTarget.value.replace(/,/g, ".").replace(/-/g, "")
              ),
            }),
            false
          );
        }}
        onBlur={(e) =>
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: parseFloat(
                e.currentTarget.value.replace(/,/g, ".").replace(/-/g, "")
              ),
            }),
            true
          )
        }
        onKeyDown={(e) => {
          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            isAllowedOnNumberTypingKey(e.keyCode)
          )
            e.stopPropagation();
          if (
            (!inNumericKey(e.keyCode) &&
              !isNavigationKey(e.keyCode) &&
              !isAllowedOnNumberTypingKey(e.keyCode)) ||
            e.shiftKey
          )
            e.preventDefault();
        }}
        onPointerDown={(e) => e.stopPropagation()}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
      />
    );
  }
}
