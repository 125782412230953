import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Avatar, Button, Tooltip } from "@fluentui/react-components";
import { GuestRegular } from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import moment from "moment";
import * as React from "react";
import { DocumentTableRegular } from "@fluentui/react-icons";
export interface DeclaredCell extends Cell {
  type: "declared";
  link?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  resolvementSuggestFn?: any;
  clearFn?: any;
  rowId?: any;
  clientId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  declaredAt: string;
  declaredByUserEmail: string;
  declaredByUserId: string;
  declaredByUserName: string;
  selected?: boolean;
  highlighted?: boolean;
  isDeclaredViaWebApi?: boolean;
  isDeclaredManually?: boolean;
  t: any;
}

export class DeclaredCellTemplate implements CellTemplate<DeclaredCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<DeclaredCell>
  ): Compatible<DeclaredCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let declaredAt = null;
    try {
      declaredAt = getCellProperty(uncertainCell, "declaredAt", "string");
    } catch {
      declaredAt = null;
    }

    let declaredByUserEmail = null;
    try {
      declaredByUserEmail = getCellProperty(
        uncertainCell,
        "declaredByUserEmail",
        "string"
      );
    } catch {
      declaredByUserEmail = null;
    }

    let declaredByUserId = null;
    try {
      declaredByUserId = getCellProperty(
        uncertainCell,
        "declaredByUserId",
        "string"
      );
    } catch {
      declaredByUserId = null;
    }

    let declaredByUserName = null;
    try {
      declaredByUserName = getCellProperty(
        uncertainCell,
        "declaredByUserName",
        "string"
      );
    } catch {
      declaredByUserName = null;
    }

    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }

    let isDeclaredViaWebApi: boolean | undefined;
    try {
      isDeclaredViaWebApi = getCellProperty(
        uncertainCell,
        "isDeclaredViaWebApi",
        "boolean"
      );
    } catch {
      isDeclaredViaWebApi = true;
    }

    let isDeclaredManually: boolean | undefined;
    try {
      isDeclaredManually = getCellProperty(
        uncertainCell,
        "isDeclaredManually",
        "boolean"
      );
    } catch {
      isDeclaredManually = true;
    }

    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    const text = "";
    return {
      ...uncertainCell,
      text,

      value: NaN,
      declaredAt,
      declaredByUserEmail,
      declaredByUserId,
      declaredByUserName,
      selected,
      highlighted,
      isDeclaredViaWebApi,
      isDeclaredManually,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(cell: Compatible<DeclaredCell>, isInEditMode: boolean): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<DeclaredCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DeclaredCell>, commit: boolean) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <TooltipHost
        content={
          cell.isDeclaredManually
            ? cell.t("pct.declaredManuallyCellTooltip.label", [
                cell.declaredByUserName ??
                  cell.declaredByUserEmail ??
                  cell.declaredByUserId ??
                  "Unknown",
                moment(cell.declaredAt).format("DD.MM.YYYY HH:mm"),
              ])
            : cell.t("pct.declaredCellTooltip.label", [
                cell.declaredByUserName ??
                  cell.declaredByUserEmail ??
                  cell.declaredByUserId ??
                  "Unknown",
                moment(cell.declaredAt).format("DD.MM.YYYY HH:mm"),
              ])
        }
        directionalHint={DirectionalHint.rightCenter}
        hidden={false}
      >
        {cell.declaredByUserName ||
        cell.declaredByUserEmail ||
        cell.declaredByUserId ||
        cell.declaredAt ? (
          <Avatar
            color="colorful"
            size={20}
            icon={<GuestRegular />}
            name={
              cell.declaredByUserName
                ? cell.declaredByUserName.split(" - ")[0]
                : cell.declaredByUserEmail ?? cell.declaredByUserId ?? "Unknown"
            }
            badge={
              cell.isDeclaredViaWebApi
                ? { status: "available" }
                : cell.isDeclaredManually
                ? { status: "away" }
                : null
            }
          />
        ) : null}
      </TooltipHost>
    </div>
  );
};
