import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { LoadStatus } from "types/types";

import {
  declarePayments,
  generateExcel,
  setStoreValue,
  setStoreValues,
} from "./PaymentDeclarationDialogStoreActions";
import { PaymentsMetadataMap } from "utils/types";

export type StoreState = {
  declarePaymentsStatus: LoadStatus;
  declarePaymentsDialog: {
    isOpen: boolean;
    grecoCompanyNumber: number;
    paymentIds: number[];
    manualDeclaration: boolean;
  };
  declaredPayments: any[];
  declaredPaymentsMetadataMap: PaymentsMetadataMap;
  declaredPaymentsSort: any;
  declaredTotal: number;
  declareTotalAmount: number;
  declarationEndpointResult: string | null;

  insurerReportDialog: {
    isOpen: boolean;
    grecoCompanyNumber: number;
    paymentIds: number[];
  };
  generateExcelStatus: LoadStatus;
  excelReportResponse: any;
};
// This is the value of the store on initialisation
const initialState: StoreState = {
  declarePaymentsStatus: "none",
  declarePaymentsDialog: {
    isOpen: false,
    grecoCompanyNumber: null,
    paymentIds: [],
    manualDeclaration: false,
  },
  declaredPayments: [],
  declaredPaymentsMetadataMap: {},
  declaredPaymentsSort: null,
  declaredTotal: 0,
  declareTotalAmount: 0,
  declarationEndpointResult: null,

  insurerReportDialog: {
    isOpen: false,
    grecoCompanyNumber: null,
    paymentIds: [],
  },
  generateExcelStatus: "none",
  excelReportResponse: null,
};

// All the actions that mutate the store
const actions = {
  setPaymentDeclarationStoreValue: setStoreValue,
  setPaymentDeclarationStoreValues: setStoreValues,
  generateExcel: generateExcel,
  declarePayments: declarePayments,
};

const PaymentDeclarationDialogStore = createStore({
  initialState,
  actions,
  name: "PaymentDeclarationDialogStore",
});
export const usePaymentDeclarationDialogStore = createHook(
  PaymentDeclarationDialogStore
);
export const usePaymentDeclarationDialogStoreState = createStateHook(
  PaymentDeclarationDialogStore
);
export const usePaymentDeclarationDialogStoreActions = createActionsHook(
  PaymentDeclarationDialogStore
);

export const usePaymentDeclarationPageStore = createHook(
  PaymentDeclarationDialogStore
);
export const usePaymentPageStoreState = createStateHook(
  PaymentDeclarationDialogStore
);
export const usePaymentPageStoreActions = createActionsHook(
  PaymentDeclarationDialogStore
);

export const useDeclarePaymentsStatus = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      declarePaymentsStatus: state.declarePaymentsStatus,
    }),
  }
);

export const useDeclarePaymentsDialog = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      declarePaymentsDialog: state.declarePaymentsDialog,
    }),
  }
);

export const useDeclarationEndpointResult = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      declarationEndpointResult: state.declarationEndpointResult,
    }),
  }
);

export const useDeclaredPayments = createHook(PaymentDeclarationDialogStore, {
  selector: (state) => ({
    declaredPayments: state.declaredPayments,
  }),
});

export const useDeclaredTotal = createHook(PaymentDeclarationDialogStore, {
  selector: (state) => ({
    declaredTotal: state.declaredTotal,
  }),
});

export const useDeclareTotalAmount = createHook(PaymentDeclarationDialogStore, {
  selector: (state) => ({
    declareTotalAmount: state.declareTotalAmount,
  }),
});

export const useDeclaredPaymentsMetadataMap = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      declaredPaymentsMetadataMap: state.declaredPaymentsMetadataMap,
    }),
  }
);

export const useDeclaredPaymentsSort = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      declaredPaymentsSort: state.declaredPaymentsSort,
    }),
  }
);

export const useGenerateExcelStatus = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      generateExcelStatus: state.generateExcelStatus,
    }),
  }
);

export const useInsurerReportDialog = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      insurerReportDialog: state.insurerReportDialog,
    }),
  }
);

export const useExcelReportResponse = createHook(
  PaymentDeclarationDialogStore,
  {
    selector: (state) => ({
      excelReportResponse: state.excelReportResponse,
    }),
  }
);
