import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import {
  DeclaredAmountCell,
  DeclaredAmountCellTemplate,
} from "../customcells/DeclaredAmountCellTemplate";

class DeclaredAmountCellTypeHandler implements ICellTypeHandler {
  cellType = "declaredamount";
  isCustom = true;
  instance = new DeclaredAmountCellTemplate();
  determineNewValue = (change: any) =>
    (change.newCell as DeclaredAmountCell).value;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const [{ declaredPayments }, { setPaymentDeclarationStoreValue }] =
      context["paymentDeclarationDialogStore"];
    return {
      type: "declaredamount",
      value: entry[columnConfig.columnId],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      multiSelected: rowsMetadataMap[entry[keyColumn]]?.multiSelected ?? false,
      setMultiselected: (value: boolean) => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        rowsMetadataMap2[entry[keyColumn]].multiSelected = value;
        setPaymentDeclarationStoreValue(
          "declaredPaymentsMetadataMap",
          rowsMetadataMap2
        );
      },
      setMultiselectedRange: () => {
        const index = Array.from(declaredPayments).findIndex((plan) => {
          if (plan[keyColumn] === entry[keyColumn]) {
            return true;
          }
          return false;
        });
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        // iterate backwards from index to the first element of the array where multiselected is true or beginning of the array
        for (let i = index; i >= 0; i--) {
          if (
            rowsMetadataMap2[declaredPayments[i][keyColumn]].multiSelected ===
            true
          ) {
            break;
          }
          rowsMetadataMap2[declaredPayments[i][keyColumn]].multiSelected = true;
        }
        setPaymentDeclarationStoreValue(
          "declaredPaymentsMetadataMap",
          rowsMetadataMap2
        );
      },
      areThereMultiselectedRows: () => {
        return declaredPayments.some(
          (plan) => rowsMetadataMap[plan[keyColumn]]?.multiSelected === true
        );
      },
      deselectMultiselectedRows: () => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        for (let plan of declaredPayments) {
          rowsMetadataMap2[plan[keyColumn]].multiSelected = false;
        }
        setPaymentDeclarationStoreValue(
          "declaredPaymentsMetadataMap",
          rowsMetadataMap2
        );
      },
      clearMultiselectedRows: () => {
        const totalAmount = declaredPayments.reduce(
          (accumulator, current) => accumulator + current.premiumAmount,
          0
        );
        // Iterate through multiselected plans and set the suggested payment amount to the premium debt values up to the total amount
        let total = 0;
        const updatedPlans = declaredPayments.map((plan) => {
          if (rowsMetadataMap[plan[keyColumn]]?.multiSelected === true) {
            const premiumAmount = plan.premiumAmount;

            const declaredAmount =
              premiumAmount > totalAmount - total
                ? totalAmount - total
                : premiumAmount;
            total += declaredAmount;
            return { ...plan, declaredAmount };
          } else {
            return plan;
          }
        });
        setPaymentDeclarationStoreValue("declaredPayments", updatedPlans);
      },

      multiCleared: rowsMetadataMap[entry[keyColumn]]?.multiCleared ?? false,
      setMultiCleared: (value: boolean) => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        rowsMetadataMap2[entry[keyColumn]].multiCleared = value;
        setPaymentDeclarationStoreValue(
          "declaredPaymentsMetadataMap",
          rowsMetadataMap2
        );
      },
      setMultiClearedRange: () => {
        const index = Array.from(declaredPayments).findIndex((plan) => {
          if (plan[keyColumn] === entry[keyColumn]) {
            return true;
          }
          return false;
        });
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        // iterate backwards from index to the first element of the array where multicleared is true or beginning of the array
        for (let i = index; i >= 0; i--) {
          if (
            rowsMetadataMap2[declaredPayments[i][keyColumn]].multiCleared ===
            true
          ) {
            break;
          }
          rowsMetadataMap2[declaredPayments[i][keyColumn]].multiCleared = true;
        }
        setPaymentDeclarationStoreValue(
          "declaredPaymentsMetadataMap",
          rowsMetadataMap2
        );
      },
      areThereMultiClearedRows: () => {
        return declaredPayments.some(
          (plan) => rowsMetadataMap[plan[keyColumn]]?.multiCleared === true
        );
      },
      deselectMultiClearedRows: () => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        for (let plan of declaredPayments) {
          rowsMetadataMap2[plan[keyColumn]].multiCleared = false;
        }
        setPaymentDeclarationStoreValue(
          "declaredPaymentsMetadataMap",
          rowsMetadataMap2
        );
      },
      clearMultiClearedRows: () => {
        const updatedPlans = declaredPayments.map((plan) => {
          if (rowsMetadataMap[plan[keyColumn]]?.multiCleared === true) {
            rowsMetadataMap[plan[keyColumn]].multiCleared = false;
            return { ...plan, declaredAmount: 0 };
          } else {
            return plan;
          }
        });
        setPaymentDeclarationStoreValue("declaredPayments", updatedPlans);
      },
      premiumAmount: entry["premiumAmount"],
      getDeclareDataTotal: () => {
        let sum = 0;
        (declaredPayments as any[]).forEach((el) => {
          sum += el.declaredAmount;
        });
        return sum;
      },
      totalToBeDeclared: declaredPayments.reduce(
        (accumulator, current) => accumulator + current.premiumAmount,
        0
      ),
      t,
    };
  };
}

export default DeclaredAmountCellTypeHandler;
