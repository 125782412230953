import { Button, Spinner } from "@fluentui/react-components";
import React from "react";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { DocumentCheckmarkRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import {
  useDeclarePaymentsDialog,
  useDeclarePaymentsStatus,
  useDeclaredPayments,
  usePaymentDeclarationDialogStoreActions,
} from "store/PaymentDeclarationDialogStore";
import { useAppStore } from "store/AppStore";
import moment from "moment";

const DeclareButton = ({ disabled }) => {
  const { t } = useTranslation();

  const paymentPageStore = usePaymentPageStore();
  const [
    {
      fromDateFilter,
      toDateFilter,
      searchInsurersSelectedItem,
      searchClientsSelectedItem,
      searchServiceSegmentItem,
      searchCurrencyItem,
      searchPolicyNumber,
      searchIsDiscountPaidItem,
      searchDateFilterField,
    },
    { loadPayments },
  ] = paymentPageStore;

  const [{ declaredPayments }] = useDeclaredPayments();
  const [{ declarePaymentsStatus }] = useDeclarePaymentsStatus();
  const [{ declarePaymentsDialog }] = useDeclarePaymentsDialog();
  const [{ updatedGrecoCompanies }] = useAppStore();
  const { declarePayments, setPaymentDeclarationStoreValue } =
    usePaymentDeclarationDialogStoreActions();

  function formatJSON(input) {
    if (typeof input === "object" && input !== null) {
      // If input is an object or array (arrays are also objects in JavaScript)
      return JSON.stringify(input, null, 2); // This will format the JSON with 2-space indentation
    } else {
      // If input is not an object/array, return it as is
      return input;
    }
  }

  return (
    <Button
      appearance="primary"
      disabled={declarePaymentsStatus === "loading" || disabled}
      icon={
        declarePaymentsStatus === "loading" ? (
          <Spinner size="tiny" />
        ) : (
          <DocumentCheckmarkRegular fontSize={24} />
        )
      }
      onClick={() => {
        declarePayments(
          declarePaymentsDialog.manualDeclaration,
          {
            PaymentQueryRequest: {
              IncludeOnlyIds: declaredPayments.map((el) =>
                Number(el.paymentId)
              ),
              ClientIds: searchClientsSelectedItem?.value?.id
                ? [searchClientsSelectedItem?.value?.id]
                : [],
              InsurerIds: searchInsurersSelectedItem?.value?.id
                ? [searchInsurersSelectedItem?.value?.id]
                : [],
              From: fromDateFilter
                ? moment(fromDateFilter).format("YYYY-MM-DD")
                : null,
              To: toDateFilter
                ? moment(toDateFilter).format("YYYY-MM-DD")
                : null,
              ServiceSegmentCodes: searchServiceSegmentItem
                ? [searchServiceSegmentItem]
                : [],
              IsBrokerPremiumCollection: false,
              CurrencyCode: Number(searchCurrencyItem),
              PolicyNumbers:
                searchPolicyNumber && searchPolicyNumber !== ""
                  ? [searchPolicyNumber]
                  : [],
              PolicySubnumbers: [],
              DateFilterField: searchDateFilterField,
              GrecoCompanyNumbers: [declarePaymentsDialog.grecoCompanyNumber],
              IsDiscountQuery:
                searchIsDiscountPaidItem === "yes"
                  ? true
                  : searchIsDiscountPaidItem === "no"
                  ? false
                  : null,
            },
            SpecialPaymentDeclarationInfo: Object.fromEntries(
              new Map(
                declaredPayments.map((obj) => [
                  obj.paymentId + "",
                  obj.declaredAmount,
                ])
              )
            ),
          },

          (data) => {
            setPaymentDeclarationStoreValue(
              "declarationEndpointResult",
              formatJSON(data)
            );
            loadPayments(updatedGrecoCompanies);
          }
        );
      }}
    >
      {t("pct.declarePayments.label")}
    </Button>
  );
};

export default React.memo(DeclareButton);
