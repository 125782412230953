import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import {
  ClearedAmountCell,
  ClearedAmountCellTemplate,
} from "../customcells/ClearedAmountCellTemplate";

class ClearedAmountCellTypeHandler implements ICellTypeHandler {
  cellType = "clearedamount";
  isCustom = true;
  instance = new ClearedAmountCellTemplate();
  determineNewValue = (change: any) =>
    (change.newCell as ClearedAmountCell).value;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const [
      {
        clTotal,
        clBankStatementEntry,
        clUnsettledPaymentPlans,
        clientSaldo,
        clCurrencyCodesSelectedItem,
      },
      { setBankStatementsStoreValue },
    ] = context["bankStatementsStore"];
    return {
      type: "clearedamount",
      value: entry[columnConfig.columnId],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      multiSelected: rowsMetadataMap[entry[keyColumn]]?.multiSelected ?? false,
      setMultiselected: (value: boolean) => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        rowsMetadataMap2[entry[keyColumn]].multiSelected = value;
        setBankStatementsStoreValue(
          "clUnsettledPaymentPlanMetadataMap",
          rowsMetadataMap2
        );
      },
      setMultiselectedRange: () => {
        const index = Array.from(clUnsettledPaymentPlans).findIndex((plan) => {
          if (plan[keyColumn] === entry[keyColumn]) {
            return true;
          }
          return false;
        });
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        // iterate backwards from index to the first element of the array where multiselected is true or beginning of the array
        for (let i = index; i >= 0; i--) {
          if (
            rowsMetadataMap2[clUnsettledPaymentPlans[i][keyColumn]]
              .multiSelected === true
          ) {
            break;
          }
          rowsMetadataMap2[
            clUnsettledPaymentPlans[i][keyColumn]
          ].multiSelected = true;
        }
        setBankStatementsStoreValue(
          "clUnsettledPaymentPlanMetadataMap",
          rowsMetadataMap2
        );
      },
      areThereMultiselectedRows: () => {
        return clUnsettledPaymentPlans.some(
          (plan) => rowsMetadataMap[plan[keyColumn]]?.multiSelected === true
        );
      },
      deselectMultiselectedRows: () => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        for (let plan of clUnsettledPaymentPlans) {
          rowsMetadataMap2[plan[keyColumn]].multiSelected = false;
        }
        setBankStatementsStoreValue(
          "clUnsettledPaymentPlanMetadataMap",
          rowsMetadataMap2
        );
      },
      clearMultiselectedRows: () => {
        const totalAmount = clientSaldo?.clientSaldoAmountAndCurrency.find(
          (saldo) => saldo.currencyCode === clCurrencyCodesSelectedItem?.value
        )?.clientSaldoAmount;
        // Iterate through multiselected plans and set the suggested payment amount to the premium debt values up to the total amount
        let total = 0;
        const updatedPlans = clUnsettledPaymentPlans.map((plan) => {
          if (rowsMetadataMap[plan[keyColumn]]?.multiSelected === true) {
            const premiumDebt = clientSaldo?.isGrECoClient
              ? plan.premiumDiscountDebt
              : plan.premiumDebt;

            let suggestedPaymentAmount =
              premiumDebt > totalAmount - total
                ? totalAmount - total
                : premiumDebt;
            suggestedPaymentAmount =
              Math.round(suggestedPaymentAmount * 100) / 100;
            total += suggestedPaymentAmount;
            rowsMetadataMap[plan[keyColumn]].multiSelected = false;
            return { ...plan, suggestedPaymentAmount };
          } else {
            return plan;
          }
        });
        setBankStatementsStoreValue("clUnsettledPaymentPlans", updatedPlans);
      },

      multiCleared: rowsMetadataMap[entry[keyColumn]]?.multiCleared ?? false,
      setMultiCleared: (value: boolean) => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        rowsMetadataMap2[entry[keyColumn]].multiCleared = value;
        setBankStatementsStoreValue(
          "clUnsettledPaymentPlanMetadataMap",
          rowsMetadataMap2
        );
      },
      setMultiClearedRange: () => {
        const index = Array.from(clUnsettledPaymentPlans).findIndex((plan) => {
          if (plan[keyColumn] === entry[keyColumn]) {
            return true;
          }
          return false;
        });
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        // iterate backwards from index to the first element of the array where multicleared is true or beginning of the array
        for (let i = index; i >= 0; i--) {
          if (
            rowsMetadataMap2[clUnsettledPaymentPlans[i][keyColumn]]
              .multiCleared === true
          ) {
            break;
          }
          rowsMetadataMap2[clUnsettledPaymentPlans[i][keyColumn]].multiCleared =
            true;
        }
        setBankStatementsStoreValue(
          "clUnsettledPaymentPlanMetadataMap",
          rowsMetadataMap2
        );
      },
      areThereMultiClearedRows: () => {
        return clUnsettledPaymentPlans.some(
          (plan) => rowsMetadataMap[plan[keyColumn]]?.multiCleared === true
        );
      },
      deselectMultiClearedRows: () => {
        let rowsMetadataMap2 = { ...rowsMetadataMap };
        for (let plan of clUnsettledPaymentPlans) {
          rowsMetadataMap2[plan[keyColumn]].multiCleared = false;
        }
        setBankStatementsStoreValue(
          "clUnsettledPaymentPlanMetadataMap",
          rowsMetadataMap2
        );
      },
      clearMultiClearedRows: () => {
        const updatedPlans = clUnsettledPaymentPlans.map((plan) => {
          if (rowsMetadataMap[plan[keyColumn]]?.multiCleared === true) {
            rowsMetadataMap[plan[keyColumn]].multiCleared = false;
            return { ...plan, suggestedPaymentAmount: 0 };
          } else {
            return plan;
          }
        });
        setBankStatementsStoreValue("clUnsettledPaymentPlans", updatedPlans);
      },
      premiumDebt: clBankStatementEntry?.isGrEcoPayment
        ? entry["premiumDiscountDebt"]
        : entry["premiumDebt"],
      getClearanceDataTotal: () => {
        return clTotal;
      },
      totalToBeCleared: clBankStatementEntry.amount,
      t,
    };
  };
}

export default ClearedAmountCellTypeHandler;
