import React, { useEffect } from "react";
import { CopyRegular, ArrowDownloadRegular } from "@fluentui/react-icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Spinner,
  Textarea,
} from "@fluentui/react-components";

import { useTranslation } from "react-i18next";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { useAppStoreState } from "store/AppStore";

import { compareNumbersUpToTwoDecimals } from "utils/utils";
import { formatNumber } from "utils/number";
import DeclareButton from "./DeclareButton";
import DeclarePaymentsGrid from "./DeclarePaymentsGrid";
import {
  useDeclarationEndpointResult,
  useDeclarePaymentsDialog,
  useDeclarePaymentsStatus,
  useDeclareTotalAmount,
  useDeclaredTotal,
  useExcelReportResponse,
  useGenerateExcelStatus,
  usePaymentDeclarationDialogStoreActions,
} from "store/PaymentDeclarationDialogStore";
import InsurerReportButton from "./InsurerReportButton";
import { VSpace } from "components/Spacer";

const DeclarePaymentsDialog = () => {
  const { t } = useTranslation();
  const paymentPageStore = usePaymentPageStore();
  const { taxonomy, updatedGrecoCompanies } = useAppStoreState();
  const [
    {
      fromDateFilter,
      toDateFilter,
      searchInsurersSelectedItem,
      searchCurrencyItem,
    },
  ] = paymentPageStore;

  const [{ declarationEndpointResult }] = useDeclarationEndpointResult();

  const [{ declaredTotal }] = useDeclaredTotal();
  const [{ declarePaymentsStatus }] = useDeclarePaymentsStatus();

  const [{ declareTotalAmount }] = useDeclareTotalAmount();
  const { setPaymentDeclarationStoreValue, setPaymentDeclarationStoreValues } =
    usePaymentDeclarationDialogStoreActions();

  const [{ declarePaymentsDialog }] = useDeclarePaymentsDialog();
  const [{ payments, paymentsMetadataMap }] = usePaymentPageStore();
  const [{ excelReportResponse }] = useExcelReportResponse();
  const [{ generateExcelStatus }] = useGenerateExcelStatus();

  useEffect(() => {
    if (declarePaymentsDialog.isOpen) {
      const orderMap = new Map(
        payments.map((item, index) => [item.paymentId, index])
      );
      setPaymentDeclarationStoreValues({
        declaredPayments: Object.keys(paymentsMetadataMap ?? {})
          .filter((el) => paymentsMetadataMap[el].selected)
          .map((el) => {
            const payment = payments.find(
              (payment) => payment.paymentId + "" === el
            );
            return {
              ...payment,
              declaredPaymentId: payment.paymentId,
              declaredAmount:
                payment.declaredPremiumAmount !== null
                  ? payment.declaredPremiumAmount
                  : payment.premiumAmount,
            };
          })
          .sort(
            (a, b) => orderMap.get(a.paymentId) - orderMap.get(b.paymentId)
          ),
        declaredPaymentsMetadataMap: Object.keys(paymentsMetadataMap ?? {})
          .filter((el) => paymentsMetadataMap[el].selected)
          .map((el) => {
            return {
              key: el,
              value: {
                ...paymentsMetadataMap[el],
                selected: false,
                oldRow: null,
                changes: [],
              },
            };
          })
          .reduce((accumulator, current) => {
            accumulator[current.key] = current.value;
            return accumulator;
          }, {}),
      });
    }
  }, [declarePaymentsDialog.isOpen, payments, paymentsMetadataMap]);

  if (
    searchInsurersSelectedItem === null // ||
    // fromDateFilter === null ||
    // toDateFilter === null
  ) {
    return null;
  }

  return (
    <Dialog modalType="modal" open={declarePaymentsDialog.isOpen}>
      <DialogSurface
        style={{
          maxWidth:
            declarationEndpointResult === null && excelReportResponse === null
              ? "96%"
              : "42%",
        }}
      >
        <DialogBody>
          <DialogTitle>
            {declarePaymentsDialog.manualDeclaration
              ? `${t("pct.declarePaymentsManual.label")} -`
              : ""}{" "}
            {t("pct.declarePaymentsTitle.label", [
              declarePaymentsDialog.paymentIds.length === 0
                ? t("pct.all.label")
                : declarePaymentsDialog.paymentIds.length,
              searchInsurersSelectedItem?.label,
            ])}
          </DialogTitle>
          <DialogContent>
            {declarationEndpointResult !== null ? (
              <Field label="Response">
                <Textarea
                  className="declarePaymentsTextArea"
                  rows={10}
                  textarea={<textarea style={{ height: "100% !important" }} />}
                  value={declarationEndpointResult}
                  onChange={(data) =>
                    setPaymentDeclarationStoreValue(
                      "declarationEndpointResult",
                      data
                    )
                  }
                />
              </Field>
            ) : excelReportResponse ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    paddingTop: "40px",
                    textAlign: "center",
                  }}
                >
                  {excelReportResponse
                    ? t("pct.generateReportFinished.label")
                    : null}
                  <br />
                  <VSpace height={20} />
                  <Button
                    appearance="primary"
                    disabled={generateExcelStatus === "loading"}
                    icon={<ArrowDownloadRegular fontSize={24} />}
                    onClick={() => {
                      window.open(excelReportResponse.url, "_blank");
                      setPaymentDeclarationStoreValues({
                        declarePaymentsDialog: {
                          isOpen: false,
                          grecoCompanyNumber: null,
                        },
                        excelReportResponse: null,
                        declarationEndpointResult: null,
                      });
                    }}
                  >
                    {t("pct.downloadReport.label")}
                  </Button>
                </div>
              </>
            ) : (
              <DeclarePaymentsGrid />
            )}
            {declarationEndpointResult === null &&
            excelReportResponse === null ? (
              <div style={{ float: "right", paddingTop: "24px" }}>
                {t("pct.toBeDeclaredAmount.label")}:{" "}
                <span
                  style={{ fontWeight: "bold" }}
                  className={
                    (declareTotalAmount >= 0 &&
                      compareNumbersUpToTwoDecimals(
                        declaredTotal,
                        declareTotalAmount
                      ) > 0) ||
                    (declareTotalAmount < 0 && declaredTotal !== 0)
                      ? "redcolor"
                      : ""
                  }
                >
                  {formatNumber(declaredTotal, 2, 2)}
                </span>
                /{formatNumber(declareTotalAmount, 2, 2)}
                &nbsp;
                {t(taxonomy.Currency.byId[searchCurrencyItem].code)}
              </div>
            ) : (
              <VSpace height={100} />
            )}
          </DialogContent>
          <DialogActions>
            <>
              <Button
                appearance="secondary"
                disabled={declarePaymentsStatus === "loading"}
                onClick={() => {
                  setPaymentDeclarationStoreValues({
                    declarePaymentsDialog: {
                      isOpen: false,
                      grecoCompanyNumber: null,
                    },
                    excelReportResponse: null,
                    declarationEndpointResult: null,
                  });
                }}
              >
                {t("pct.close.label")}
              </Button>
              {declarationEndpointResult !== null ? (
                <Button
                  appearance="primary"
                  disabled={declarePaymentsStatus === "loading"}
                  icon={
                    declarePaymentsStatus === "loading" ? (
                      <Spinner size="tiny" />
                    ) : (
                      <CopyRegular fontSize={24} />
                    )
                  }
                  onClick={() => {
                    navigator.clipboard
                      .writeText(declarationEndpointResult)
                      .then(() => {})
                      .catch(() => {});
                    // setPaymentStoreValue("declarePaymentsDialog", {
                    //   isOpen: false,
                    //   grecoCompanyNumber: null,
                    // });
                  }}
                >
                  {t("pct.copyToClipboard")}
                </Button>
              ) : declarationEndpointResult === null &&
                excelReportResponse === null ? (
                <>
                  <InsurerReportButton disabled={declareTotalAmount < 0} />
                  <DeclareButton disabled={declareTotalAmount < 0} />
                </>
              ) : null}
            </>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DeclarePaymentsDialog;
